import { APP_ROUTES, DASHBOARD_SIDEBAR_ROUTES } from 'constants/routeConstants';
import { lazy } from 'react';
import { createRoute } from './utils';

const Login = lazy(() => import('pages/Login'));
const Register = lazy(() => import('pages/Register'));
const Dashboard = lazy(() => import('pages/Dashboard'));
const ForgotPassword = lazy(() => import('pages/ForgotPassword'));
const ResetPassword = lazy(() => import('pages/ResetPassword'));
const OtpVerification = lazy(() => import('pages/MFAlogin'));
const AWSConnect = lazy(() => import('pages/AWSConnect'));

const dashboardRoutes = Object.values(DASHBOARD_SIDEBAR_ROUTES).map((route) =>
  createRoute(route, Dashboard, true),
); // Temporary fix coz need to use sub-routes

const routes = [
  createRoute(APP_ROUTES.ROOT, Login),
  createRoute(APP_ROUTES.LOGIN, Login),
  createRoute(APP_ROUTES.REGISTER, Register),
  createRoute(APP_ROUTES.FORGOT_PASSWORD, ForgotPassword),
  createRoute(APP_ROUTES.RESET_PASSWORD, ResetPassword),
  createRoute(APP_ROUTES.OTP_VERIFICATION, OtpVerification),
  createRoute(APP_ROUTES.AWS_CONNECT, AWSConnect),
  // createRoute(APP_ROUTES.DASHBOARD, Dashboard, true),
  ...dashboardRoutes,
];

export default routes;
